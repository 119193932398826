<template>
  <div class="LeetCodeVideo">
    <div class="container">
      <img src="@/assets/img/course/Class2/banner1.png" alt="" />
      <div class="leet-swiper">
		  <img src="@/assets/img/course/Class2/leet-pic.jpg" alt="" />
		  <div class="leet-card">
			  <LCfree></LCfree>
		  </div>
	  </div>
      <img src="@/assets/img/course/Class2/banner2.png" alt="" />
      <img src="@/assets/img/course/Class2/banner3.png" alt="" />
      <img src="@/assets/img/course/Class2/banner4.png" alt="" />
      <img src="@/assets/img/course/Class2/banner5.png" alt="" />
      <img src="@/assets/img/course/Class2/banner6.png" alt="" />
      <img src="@/assets/img/course/Class2/banner7.png" alt="" />
      <img src="@/assets/img/course/Class2/banner8.png" alt="" />
      <img src="@/assets/img/course/Class2/banner9.png" alt="" />
      <img src="@/assets/img/course/Class2/banner10.png" alt="" />
      <img src="@/assets/img/course/Class2/banner11.png" alt="" />
      <img src="@/assets/img/course/Class2/banner12.png" alt="" />
    </div>
    <div class="prices">
      <span class="usdPrice">${{ courseCombosList[0].usdprice }}/</span>
      <span class="rmbPrice">￥{{ courseCombosList[0].price }} </span>
      <div class="buy" @click="buyNow(courseCombosList[0])">立即购买</div>
    </div>
  </div>
</template>
<script>
import { getCourseDetail, getMinimumPrice } from "@/service/course";
import PolyVideo from "@/components/PolyVideo";
import LCfree from "./components/LCfree.vue";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "Class2",
  components: { PolyVideo ,LCfree},
  data() {
    return {
      courseCombosList: [],
      miniumPrice: {},
    };
  },
  mounted() {
    getCourseDetail(13).then((res) => {
      this.courseCombosList = res.result.courseCombosList;
    });
    // getMinimumPrice().then((res) => {
    //   if (res.success) {
    //     this.miniumPrice = res.result;
    //   }
    // });
  },
  methods: {
    buyNow(item) {
      setStorageForArray("order", [item]);
      this.$router.push("/pay/confirm");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/css/course.scss");
.LeetCodeVideo {
  width: 100%;
  .container {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}
.leet-swiper{
	position: relative;
}
.leet-card{
	padding: 0 30px;
	box-sizing: border-box;
	left: 0;
	bottom: 175px;
	width: 100%;
	position: absolute;
}
@media (min-width:370px) {
	.leet-card{
		bottom: 183px;
	}
}
@media (min-width:390px) {
	.leet-card{
		bottom: 193px;
	}
}
@media (min-width:413px) {
	.leet-card{
		bottom: 210px;
	}
}






</style>